import { createAction, props } from '@ngrx/store';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { CompanyContextForTraveller } from '@shared/models/company-context-for-traveller';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { AgencyContextForCompany } from '@models/agency-context-for-company';
import { NgOption } from '@models/option';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';

export const setCurrentTraveller = createAction(
  '[profiles] set current traveller',
  props<{ traveller: TravellerDetailResponse | undefined }>()
);

export const setCompanyUuidOfTraveller = createAction(
  '[profiles] set company UUID on current traveller',
  props<{ companyUuid: string | undefined, forceContextRefresh: boolean }>()
);

export const setCompanyContextForTraveller = createAction(
  '[profiles] set company context for traveller',
  props<{ context: CompanyContextForTraveller | undefined, version: number }>()
);

export const setAgencyContextForCompany = createAction(
  '[profiles] set agency context for company',
  props<{ context: AgencyContextForCompany | undefined, version: number }>()
);

export const setCreditcards = createAction(
  '[profiles] set profile credit cards',
  props<{ creditcards: MaskedCreditCardDetails[] }>()
);

export const setTravellerRailIdentificationCardOptions = createAction(
  '[profiles] set traveller rail identification card options',
  props<{ railIdentificationCardOptions: NgOption[] }>()
);

export const setCurrentCompany = createAction(
  '[profiles] set current company',
  props<{ company: CompanyDetailResponse | undefined }>()
);

export const setAgencyUuidOfCompany = createAction(
  '[profiles] set agency UUID on current company',
  props<{ agencyUuid: string | undefined }>()
);
